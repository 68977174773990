<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改' : '添加'}区域`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%">
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="区域名称" prop="regionalName">
              <el-input clearable v-model="form.regionalName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'
import InputDialogClass from './inputDialogClass'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: new InputDialogClass('form'), // 实例化一个表单的变量
      rules: new InputDialogClass('rule', this.Language), // 实例化一个表单的规则
      tableData: [],
      choseDialog: {
        visible: false,
      },
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
      this.form = new InputDialogClass('form')
      this.tableData = []
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          // this.$emit('sureDialog', this.form.qty)
          this.$http.post(this.isEdit ? apis.areaUpdate : apis.areaAdd, this.form).then((res) => {
            if (res.data.code === 0) {
              this.closeDialog()
            }
          })
        }
      })
    },
    /**
     * @function 获取回显数据
     * @param {Object} 要传给后端获取回显用的数据，一般是data，直接把表格里的行数据传入
     */
    getQueryData(data) {
      this.$http.post(apis.areaQueryData, data).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.form = {
            ...this.form,
            ...res.data.rows,
          }
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.total-text{
  line-height: 40px;
  padding-left: 20px;
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
