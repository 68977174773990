<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改' : '添加'}设备`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%">
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input clearable v-model="form.deviceName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备类型" prop="deviceType">
              <el-select clearable v-model="form.deviceType">
                <el-option :value="item.value" :label="item.label" v-for="(item, index) in DROPDOWNBOX.DEVICE_TYPE" :key="`device_type_${index}`"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备ID" prop="deviceId">
              <el-input clearable v-model="form.deviceId" :disabled="isEdit"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="设备拉取频率" prop="frequencyNumber">
              <el-row>
                <el-col :span="20">
                  <el-input clearable v-model="form.frequencyNumber"></el-input>
                </el-col>
                <el-col :span="4">
                  <el-select clearable v-model="form.deviceType">
                    <el-option :value="item.value" :label="item.label" v-for="(item, index) in DROPDOWNBOX.FREQUENCY_UNIT" :key="`device_type_${index}`"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="区域名称" prop="regionalId">
              <el-select clearable v-model="form.regionalId" :placeholder="(DROPDOWNBOX.area && DROPDOWNBOX.area.length === 0) ? '请先添加区域' : '请选择'">
                <el-option :value="item.value" :label="item.label" v-for="(item, index) in DROPDOWNBOX.area" :key="`device_type_${index}`"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import dictMixin from '@/mixins/dictMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin, dictMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // 这是字典
      console.log("DROPDOWNBOX-字典", this.DROPDOWNBOX)
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post(this.isEdit ? apis.deviceUpdate : apis.deviceSave, this.form).then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.closeDialog()
            }
          })
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      if (this.$refs.cateTree) {
        this.$refs.cateTree.getTreeData()
      }
      if (this.isEdit) {
        // 在这里获取回显的数据
        this.$http.post(apis.deviceQuery, data).then((res) => {
          if (res.data.code === 0) {
            this.form = { ...res.data.rows }
          }
        })
      }
    },
    /**
     * @function 点击树状节点的事件
     * @param {Object} data 点击的节点数据
     */
    clickNode(data) {
      this.form.categoryId = data.id || '0'
    },
    openDialog() {
      // this.getArea()
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
