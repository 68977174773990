import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.deviceName = ''
      this.deviceType = ''
      this.deviceId = ''
      this.frequencyNumber = ''
      this.unit = ''
      this.regionalId = ''
    } else if (type === 'rule') {
      this.materialName = [
        {
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_materialName,
          trigger: 'blur',
        },
      ]
      this.specification = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_specification,
          trigger: 'blur',
        },
      ]
      this.unit = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_unit,
          trigger: 'blur',
        },
      ]
      this.purchasePrice = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_purchasePrice,
          trigger: 'blur',
        },
      ]
      this.supplierId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_supplierId,
          trigger: 'change',
        },
      ]
      this.serialNumFlag = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_serialNumFlag,
          trigger: 'change',
        },
      ]
      this.categoryId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_categoryId,
          trigger: 'change',
        },
      ]
      this.brand = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_brand,
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
