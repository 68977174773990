import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.regionalName = ''
    } else if (type === 'rule') {
      this.receiverId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_receiverId,
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
