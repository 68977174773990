<template>
  <div class="dialog-container">
    <el-dialog
      title="系统设置"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <!-- 表格 -->
      <div class="table-container">
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column
            prop="regionalName"
            align="center"
            label="区域名称">
          </el-table-column>
          <el-table-column
            prop="qty"
            align="center"
            label="已有设备数">
          </el-table-column>
          <el-table-column
            prop="option"
            align="center"
            label="操作">
            <template slot-scope="scope">
              <el-button class="default-button" @click="changeInputDialog(true, true, scope.row)">修改</el-button>
              <el-button class="default-button" v-if="scope.row.qty <= 0" @click="removeArea(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row>
        <el-col :span="12">
          <!-- 按钮 -->
          <div class="button-container">
            <el-button @click="changeInputDialog(true)" class="default-button fl">新增</el-button>
          </div>
        </el-col>
        <el-col :span="12">
          <!-- 分页 -->
          <div class="page-container">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="form.pageNum"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="form.total">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
      <!-- <template slot="footer">
        <el-button @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button @click="closeDialog">{{$t('cancel')}}</el-button>
      </template> -->
    </el-dialog>
    <!-- 输入数量弹窗 -->
    <input-dialog ref="inputDialog" :show="inputDialog.visible" :isEdit="inputDialog.isEdit" @closeDialog="changeInputDialog"></input-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'
import inputDialog from './inputDialog.vue'
import ChoseDialogClass from './choseDialogClass'

export default {
  mixins: [mixin],
  components: {
    inputDialog,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: new ChoseDialogClass('form'), // 实例化一个表单的变量
			searchWidthOpen: true, // 是否一打开就自动搜索
      tableData: [], // 表格数据
      inputDialog: {
        visible: false,
        isEdit: false,
      },
    }
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
      this.form = new ChoseDialogClass('form')
      this.tableData = []
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求,比如判断选中几条啊，调用接口什么的
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
      // if (this.selectedArr.length > 0) {
      //   this.changeInputDialog(true)
      // }
      this.closeDialog()
		},
		/**
		 * @function 搜索功能,分页在这边不考虑，只写获取数据的业务
		 */
		searchData() {
			console.log(`在这里执行搜索操作，请求第${this.form.pageNum}页，每页${this.form.pageSize}条`)
      this.$http.post(apis.areaManger, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 打开弹窗触发的事件，原本写在mixin，因为要获取树状数据，在外部覆盖
     */
    openDialog() {
      if (this.searchWidthOpen) {
        this.searchEvent()
      }
    },
    /**
     * @function 改变选择弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeInputDialog(dialogStatus, isEdit = false, data = {}) {
      if (!dialogStatus) {
        this.searchEvent()
      } else {
        this.inputDialog.isEdit = isEdit
        if (isEdit) {
          this.$refs.inputDialog.getQueryData(data)
        }
      }
      this.$nextTick(() => {
        this.inputDialog.visible = dialogStatus
      })
    },
    /**
     * @function 删除区域
     * @param {Object} data 点击删除的行数据
     */
    removeArea(data) {
      this.$http.post(apis.areaRemove, {
        id: [data.id],
      }).then((res) => {
        if (res.data.code === 0) {
          this.searchEvent()
        }
      })
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.page-container{
  display: grid;
  justify-content: end;
  margin-top: 20px;
}
.button-container{
  margin-left: 20px;
}
</style>
