class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.regionalName = ''
    } else if (type === 'rule') {
      this.regionalName = [
        {
          required: true,
          message: `请输入区域名称`,
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
